import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Job from './Job'
import ProjectFilter from '../projects/ProjectFilter'
import styles from './JobList.module.scss'

class JobList extends PureComponent {

    constructor(props) {
        super(props)
        this.onFilterChange = this.onFilterChange.bind(this)

        const {jobs, initialFilter} = props

        const visibleProjects = jobs.reduce((filtered, job) => {
            if (job.node.data.project) filtered.push(job.node.data.project.document[0].slugs[0])
            return filtered
        }, [])

        const filteredProjects = (initialFilter && visibleProjects.includes(initialFilter)) ? [initialFilter] : []

        this.state = {
            filteredProjects
        }
    }

    onFilterChange(selected) {
        this.setState(() => ({filteredProjects: selected}))
    }

    render() {
        const {jobs, locale, projects, initialFilter} = this.props
        const {filteredProjects} = this.state
        const filteredJobs = filteredProjects.length === 0
            ? jobs
            : jobs.filter(job => {
                const projectSlug = job.node.data.project ? job.node.data.project.document[0].slugs[0] : ''
                return filteredProjects.includes(projectSlug)
            })

        const visibleProjects = jobs.reduce((filtered, job) => {
            if (job.node.data.project) filtered.push(job.node.data.project.document[0].slugs[0])
            return filtered
        }, [])

        return (
            <div className={styles.Main}>
                <ProjectFilter onChange={this.onFilterChange}
                               visibleProjects={visibleProjects}
                               projects={projects}
                               initialFilter={initialFilter}
                />
                <div className={styles.List}>
                    {filteredJobs.map(({node: {uid, data}}) => {
                        const {title, project, workload, location} = data
                        return (
                            <Job key={uid}
                                 slug={uid}
                                 title={title.text}
                                 project={project ? project.document[0].data.title.text : ''}
                                 workload={workload}
                                 location={location}
                                 locale={locale}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }
}

JobList.propTypes = {
    jobs: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    initialFilter: PropTypes.string,
}

JobList.defaultProps = {
    initialFilter: ''
}

export default JobList
