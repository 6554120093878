import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import styles from './Job.module.scss'
import {AppConsumer} from '../../AppProvider'
import {CURSORS} from '../../Config'

class Job extends PureComponent {

    render() {
        const {slug, title, project, workload, location, locale} = this.props

        return (
            <AppConsumer>
                {({showCursor, hideCursor}) => (
                    <Link to={`/${locale}/jobs/${slug}`}
                          className={styles.Main}
                          onMouseEnter={() => showCursor(CURSORS.ARROW_RIGHT_RED)}
                          onMouseLeave={() => hideCursor()}
                    >
                        <div className={styles.Eyebrow}>
                            {project && <span className={styles.Item} dangerouslySetInnerHTML={{__html: project}}/>}
                            {location && <span className={styles.Item} dangerouslySetInnerHTML={{__html: location}}/>}
                            {workload && <span className={styles.Item} dangerouslySetInnerHTML={{__html: workload}}/>}
                        </div>
                        <h4 className={styles.Title} dangerouslySetInnerHTML={{__html: title}}/>
                    </Link>
                )}
            </AppConsumer>
        )
    }
}

Job.propTypes = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    workload: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired
}

export default Job
