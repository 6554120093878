import React from 'react'
import {graphql} from 'gatsby'
import {Redirect} from '@reach/router'
import Layout from '../components/layout/Layout'
import JobList from '../components/jobs/JobList'
import Headline from '../components/headline/Headline'
import Text from '../components/text/Text'
import FormContainer from '../components/form/FormContainer'
import Container from '../components/container/Container'
import RandomImages from '../components/randomImages/RandomImages'
import {sortProject} from '../misc/sortProject'
import Helmet from 'react-helmet'

const Page = ({data: {career, jobs, projects, form, minigame}, pageContext, location}) => {

    if (!career) return <Redirect to={`/${pageContext.locale}/404`} noThrow/>
    projects.edges.sort(sortProject)

    return (
        <Layout
            locale={pageContext.locale}
            lang={pageContext.lang}
            alternatePath={pageContext.alternatePath}
            alternateLocale={pageContext.alternateLocale}
        >

            <Helmet>
                <title>{`Aquilas — ${career.data.headline.text}`}</title>
            </Helmet>

            <Headline headline={career.data.headline}/>

            <Container>
                <Text text={career.data.text}/>
            </Container>

            {
                jobs &&
                <JobList jobs={jobs.edges}
                         locale={pageContext.locale}
                         projects={projects}
                         initialFilter={location.state && location.state.selectedProject}
                />
            }

            <FormContainer
                title={career.data.form_title.text}
                formdata={form.data}
                miniGameData={minigame.data}
                projects={projects.edges}
                preSelectedProject={location.state && location.state.selectedProject}/>

            <RandomImages images={career.data.random_images}/>
        </Layout>
    )
}

export const query = graphql`
query careerQuery($lang: String!)  {
  career: prismicCareer(lang: {eq: $lang}) {
    data {
      headline {
        text
      }
      text {
        html
      }
      form_title {
        text
      }
      random_images {
        image {
          url
          dimensions {
            width
            height
          }
        }
      }
    }
  }
  jobs: allPrismicJob(filter:{lang:{eq:$lang }}) {
    edges {
      node {
        uid
        data {
          title {
            text
          }
          workload
          location
          project {
            document {
              slugs
              data {
                title {
                  text
                }
                job_email
              }
            }
          }
        }
      }
    }
  }
  projects: allPrismicProject(filter:{lang:{eq:$lang }}) {
    edges {
      node {
        slugs
        data {
          title {
            text
          }
          order
          job_email
        }
      }
    }
  }
  form: prismicForm(lang: {eq: $lang}) {
    data {
      label_name
      placeholder_name
      label_email
      placeholder_email
      label_phone
      placeholder_phone
      label_project
      placeholder_project
      label_message
      placeholder_message
      label_fileupload
      placeholder_fileupload
      submit_button_text
      success {
        html
      }
      error {
        html
      }
    }
  }
  minigame: prismicMinigame(lang: {eq: $lang}) {
    data {
      introduction_text {
        html
      }
      introduction_button
      rules_text {
        html
      }
      rules_button
      result_text {
        html
      }
      result_button
    }
  }
}
`

export default Page